<template>
  <ToolTip placement="top" :description="description" icon="mdi-help-circle-outline" iconClass="tooltip-help-info" />
</template>

<script>
import ToolTip from './ToolTip'

export default {
  props: {
    description: {
      required: true
    }
  },
  components: { ToolTip }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#app .tooltip-help-info
  font-size: 18px
  top: -6px
</style>
